export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/admin": [3],
		"/anvandare": [4],
		"/api": [5],
		"/arbetsmiljopolicy": [7],
		"/arbetsmiljo": [~6],
		"/blog": [~8],
		"/blog/[slug]": [~9],
		"/fakoll": [10],
		"/faq": [~11],
		"/hitta": [12],
		"/hitta/[...filterString]": [~13],
		"/hjalp": [14],
		"/integritetspolicy": [15],
		"/jamfor": [~16],
		"/jamfor/[entryType]/[...entrySlugs]": [17],
		"/kartlaggningsmote": [19],
		"/kartlagg/[stepvalue]": [~18],
		"/kma": [~20],
		"/kontakta": [21],
		"/kunskap/arbetsmiljo": [22],
		"/kunskap/hallbarhetsredovisning": [23],
		"/kunskap/kemikaliehantering": [24],
		"/kunskap/kma": [25],
		"/kunskap/kvalitet": [26],
		"/kunskap/lagbevakning": [27],
		"/kunskap/miljo": [28],
		"/kunskap/nyaafs": [29],
		"/kunskap/rapportering": [30],
		"/kunskap/riskbedomning": [31],
		"/kunskap/rondering": [32],
		"/kunskap/utbildning": [33],
		"/kvalitetspolicy": [35],
		"/kvalitet": [~34],
		"/lagbevakning": [36],
		"/las/[id]/[token]": [~37],
		"/login": [38],
		"/login/[provider]/callback": [39],
		"/logout": [40],
		"/matcha": [~41],
		"/matcha/[stepvalue]": [42],
		"/miljopolicy": [44],
		"/miljo": [~43],
		"/om": [45],
		"/rapport": [46],
		"/roadmap": [47],
		"/safunkardet": [48],
		"/sok/[s]": [49],
		"/testa": [50],
		"/tjanst/[entryType]/[entryId]": [~51],
		"/villkor": [52]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';